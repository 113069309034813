.tutorialScreen {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
        z-index: 999;
    }
}

.skipTutorialBtn {
    background-color: $theme-yellow;
    border: 1px solid $theme-yellow;
    color: $theme-black;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    position: absolute;
    bottom: 71px;
    left: 51px;
    z-index: 1000;
    transition: 0.5s;

    @media(max-width: 1366px) {
        width: 160px;
        bottom: 25px;
    }

    @media (max-width: 768px) {
        display: none !important;
    }

    svg {
        path {
            transition: 0.5s;
            fill: $theme-black;
        }

    }

    &:hover,
    &:active,
    &:focus {
        background-color: $theme-yellow;
        border-color: $theme-yellow;
        box-shadow: none;

        svg {
            path {
                fill: white;
            }

        }
    }

    span {
        margin-right: 14px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;

        @media(max-width: 1366px) {
            font-size: 14px !important;
        }
    }
}


.tutorialStep {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: $theme-black;
    letter-spacing: 0.7px;
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    background: linear-gradient(180deg, #FDCB53 0%, #FA7C09 100%);
    border: 0;
    position: relative;

    @media (max-width: 1366px) {
        width: 40px;
        height: 40px;

    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .stepIcon {
        opacity: 0;
        visibility: hidden;

        svg {
            path {
                fill: $theme-black !important;
            }
        }
    }

    .stepCount {
        opacity: 1;
        visibility: visible;

        @media (max-width: 1366px) {
            font-size: 14px !important;
        }
    }

    &:active,
    &:focus {
        border: 0;
        color: $theme-black;
        border: 0 !important;
    }

    &:hover {
        color: $theme-black;
        border: 0 !important;

        .stepIcon {
            opacity: 1;
            visibility: visible;
        }

        .stepCount {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.steps {
    position: absolute;
    top: -7px;
    right: 20px;
    z-index: 1000;

    @media (max-width: 1366px) {
        top: 5px;
    }

    @media (max-width: 768px) {
        display: none;
    }
}

.step2 {
    top: 15px;
    right: 25px;
}

.step3 {
    top: 15px;
    right: 25px;
}


.tooltip {
    opacity: 1;
    margin-left: 1em;
    top: 50px;

    &.right {
        margin-right: 1em;
    }

    &.top {
        margin-top: 1em;
    }

    &.show {
        opacity: 1;
    }

    &.tutorialToolTip {

        &.bs-tooltip-auto[data-popper-placement^=right],
        &.bs-tooltip-end {
            padding: 0 .4rem
        }

        &.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
        &.bs-tooltip-end .tooltip-arrow {
            left: 0;
            width: .4rem;
            height: .8rem;
        }

        &.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
        &.bs-tooltip-end .tooltip-arrow::before {
            right: -1px;
            border-width: 1rem 1rem 1rem 0;
            border-right-color: white;
        }


        &.bs-tooltip-auto[data-popper-placement^=bottom],
        &.bs-tooltip-bottom {
            padding: .4rem 0
        }

        &.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,
        &.bs-tooltip-bottom .tooltip-arrow {
            top: 0;
            left: 0;
        }

        &.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
        &.bs-tooltip-bottom .tooltip-arrow::before {
            left: 0;
            bottom: -1px;
            border-width: 0 1rem 1rem;
            border-bottom-color: white
        }

        &.bs-tooltip-auto[data-popper-placement^=left],
        &.bs-tooltip-start {
            padding: 0 .4rem
        }

        &.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
        &.bs-tooltip-start .tooltip-arrow {
            right: 0;
            width: .4rem;
            height: .8rem
        }

        &.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
        &.bs-tooltip-start .tooltip-arrow::before {
            left: -1px;
            border-width: 1rem 0 1rem 1rem;
            border-left-color: white;
        }

        .tooltip-arrow {
            position: absolute;
            display: block;
            width: .8rem;
            height: .4rem;



            &::before,
            &:after {
                position: absolute;
                content: "";
                border-color: transparent;
                border-style: solid
            }

        }

        .tooltip-inner {
            min-width: 597px;
            padding: 30px 50px 45px 30px;
            border-radius: 10px;
            background-color: $theme-white;
            color: $theme-black;
            opacity: 1;

            @media (max-width: 1366px) {
                padding: 20px !important;
            }

            h4 {
                font-weight: 700;
                font-size: 24px;
                margin-bottom: 20px;
                text-align: left;

                @media (max-width: 1366px) {
                    font-size: 18px !important;
                }
            }

            p {
                text-align: left;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;

                @media (max-width: 1366px) {
                    font-size: 12px !important;
                }
            }
        }
    }


}