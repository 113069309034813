.fc-daygrid-day {
    &.disable-day {
        cursor: unset;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #f1f1f1;
        }

        a {
            &.fc-daygrid-day-number {
                &:hover {
                    cursor: not-allowed;
                }
            }

        }
    }
}