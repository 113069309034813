@media screen and (max-width: 1400px) {
    h1,
    .h1 {
        font-size: 38px;
        text-transform: uppercase;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    h3,
    .h3 {
        font-size: 26px;
    }

    h4,
    .h4 {
        font-size: 22px;
    }

    h5,
    .h5 {
        font-size: 20px;
        text-transform: uppercase;
    }

    h6,
    .h6 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }

    .nav-link,
    a {
        font-size: 14px;
    }

    span {
        font-size: 14px;
    }

    small {
        font-size: 14px;
    }

    button {
        font-size: 16px;
    }

    .dashboard-layout {
        .dashboard-page {
            .pending-post-container {
                .pending-post-holder {
                    height: 300px;
                    overflow-y: scroll;
                }
            }
        }
    }

    .stats-card {
        h4 {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 1300px) {
    h1,
    .h1 {
        font-size: 34px;
        text-transform: uppercase;
    }

    h2,
    .h2 {
        font-size: 28px;
    }

    h3,
    .h3 {
        font-size: 22px;
    }

    h4,
    .h4 {
        font-size: 18px;
    }

    h5,
    .h5 {
        font-size: 18px;
        text-transform: uppercase;
    }

    h6,
    .h6 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }

    .nav-link,
    a {
        font-size: 14px;
    }

    span {
        font-size: 14px;
    }

    small {
        font-size: 14px;
    }

    button {
        font-size: 16px;
    }

    .tooltip {
        width: 370px;
    }
}

@media screen and (max-width: 1200px) {
    h1,
    .h1 {
        font-size: 38px;
        text-transform: uppercase;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    h3,
    .h3 {
        font-size: 28px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    h5,
    .h5 {
        font-size: 20px;
        text-transform: uppercase;
    }

    h6,
    .h6 {
        font-size: 16px;
    }

    p {
        font-size: 20px;
    }

    .nav-link,
    a {
        font-size: 16px;
    }

    span {
        font-size: 16px;
    }

    small {
        font-size: 14px;
    }

    button {
        font-size: 16px;
    }

    .dashboard-layout {
        .right {
            .header {
                .btn-create-post {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        .left ul li span {
            font-size: 14px;
        }
    }

    .tooltip {
        width: 225px;
    }

    .social-account-form {
        .circle-div {
            width: 35px;
            height: 35px;
            svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    .report-page {
        .info-container {
            p {
                font-size: 20px;
            }
        }
    }

    .stats-card {
        h4 {
            font-size: 16px;
        }
    }

    .calendar-page {
        .time-slot-container {
            height: auto;
            &.cutom-margin {
                margin-top: 1.5rem;
            }
        }
    }
}

@media screen and (max-width: 1270px) {
    .social-accounts-management-page {
        .social-account-form {
            .circle-div {
                width: 30px;
                height: 30px;
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .dashboard-page {
        .swiper {
            .swiper-wrapper {
                margin-bottom: 45px;
            }
        }
        .swiper-pagination-bullet-active {
            background-color: $theme-yellow;
            bottom: -20px;
        }
    }

    .auth-layout {
        .right {
            .otp-input {
                input {
                    height: 40px;
                }
            }
        }
    }

    .stats-card {
        h4 {
            font-size: 24px;
        }
    }

    .leaderboard-page {
        .left-container {
            img {
                max-height: 205px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .report-page,
    .chart-container {
        .dropdown {
            .dropdown-toggle {
                width: 100%;
            }
        }
    }

    .auth-layout,
    .full-navigation-layout {
        overflow-y: scroll;
    }

    .dashboard-layout {
        .left ul li span {
            font-size: 12px;
        }
    }

    .stats-card {
        h4 {
            font-size: 24px;
        }
    }

    .report-page,
    .chart-container {
        .date-range-container {
            .form-control {
                width: 100%;
            }
        }
    }

    .report-page {
        .form-select {
            width: 100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .full-navigation-layout,
    .auth-layout,
    .dashboard-layout {
        height: 100%;
        overflow-y: scroll;
    }

    .body-overflow-hidden {
        overflow-y: hidden;
    }

    .body-overflow-hidden-remove {
        overflow-y: scroll;
    }

    .dashboard-layout {
        .right {
            height: 100%;
            overflow-y: unset;
        }
    }

    .create-team-post-form {
        .form-select {
            option {
                font-size: 12px;
            }
        }
    }

    .notification-modal {
        .modal-dialog {
            margin: 0 auto;
        }
    }

    .schedule-post-modal {
        .modal-dialog {
            max-width: 250px;
            margin: 0 auto;
        }
    }

    .pending-post-modal {
        .modal-dialog {
            margin: 0.5rem;
        }
    }

    .notification-modal {
        .modal-dialog {
            margin: 0.5rem;
        }
    }

    .chart-container {
        .form-select {
            width: 100%;
        }
    }

    .create-team-post-form {
        .btn-continue {
            width: 100%;
        }
    }

    .choose-plan-card {
        .description-container {
            height: auto;
        }

        .list-container {
            height: auto;
        }
    }

    .dashboard-layout {
        .left {
            display: none;
            &.activeSidebar {
                display: block;
                z-index: 999;
                padding-bottom: 20px;
                width: 100%;
                // overflow-y: scroll;
                position: absolute;
                top: 0;
                left: 0;
                height: 100vh;
                border-radius: 0;
            }

            .overflow-controller {
                height: 100vh;
                overflow: scroll;
            }
        }
    }

    .account-setup-layout {
        .half-navigation-layout {
            .header {
                h5 {
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .social-accounts-management-page {
        .social-account-form {
            .col-full-width {
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: 410px) {
    .custom-d-block {
        display: block !important;
    }

    .invite-team-form {
        &.mt-5 {
            margin-top: 1rem !important;
        }
    }

    .leaderboard-page {
        .right-container {
            .leaderboard-tabs {
                .nav-tabs {
                    flex-direction: column;
                    width: 100%;
                    padding-left: 1.5rem !important;

                    .a,
                    .nav-link {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
    }

    .full-navigation-layout {
        .skip-navlink {
            display: none;
        }
    }

    .social-account-form {
        .col-full-width {
            width: 100%;
        }
    }

    .profile-modal {
        .profile-popup-footer {
            align-items: flex-start !important;
            flex-direction: column;

            .margin-top {
                margin-top: 0.5rem;
            }

            .me-5 {
                margin-right: 0 !important;
            }

            .ms-4 {
                margin-left: 0 !important;
            }
            .ms-5 {
                margin-left: 0 !important;
            }
        }
    }
}


@media screen and (max-width: 370px) {
    .pending-post-container {
        h4,
        a,
        .nav-link {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 350px) {
    .auth-layout {
        .sub-heading {
            display: none;
        }
    }

    .account-setup-layout {
        .choose-plan-card {
            .icon-div {
                text-align: left !important;
                ul {
                    li {
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }
    }

    .create-post-modal,
    .pending-post-modal,
    .edit-post-modal,
    .notification-modal,
    .profile-modal,
    .filter-search-modal,
    .pending-submission-modal {
        .modal-content {
            .modal-body {
                label {
                    width: 42px;
                    height: 42px;
                }
            }
        }
    }
}

@media screen and (max-width: 346px) {
    .request-team-card {
        .btn-accept {
            margin-bottom: 0.5rem;
        }
    }

    .setting-navigation {
        a,
        .nav-link {
            span {
                font-size: 13px;
            }
        }
    }
}
